export default {
  HOME: '/',
  BUY_SIDE: `${process.env.REACT_APP_BUY_SIDE_URL}/`,
  ONBOARDING_VOUCHER: '/list-your-home/voucher',
  ONBOARDING_V2: '/list-your-home-v2',
  ONBOARDING_VOUCHER_V2: '/list-your-home-v2',
  DASHBOARD: '/dashboard',
  LISTINGS: '/listings',
  EDIT_LISTING: '/edit-listing/:listingId',
  VIEW_LISTING: '/listing/:listingId',
  VIEW_LISTING_SEO: '/:state/:city/:address/listing/:listingId',
  CONTACT: '/contact-us',
  ADMIN_HOME: '/admin',
  ADMIN_DATA: '/admin/:entity',
  ADMIN_ORDERS: '/admin/orders',
  ADMIN_LISTINGS: '/admin/listings',
  ADMIN_PROPERTY: '/admin/properties',
  ADMIN_PRODUCTS: '/admin/products',
  ADMIN_COMPANY: '/admin/company',
  ADMIN_TITLE_COMPANY: '/admin/title-company',
  ADMIN_EDIT_TITLE_COMPANY: '/admin/title-company/:id?',
  ADMIN_VOUCHER_TEMPLATE_LISTING: '/admin/voucher-template',
  ADMIN_VOUCHER_LISTING: '/admin/voucher-listing',
  ADMIN_USERS: '/admin/users',
  ADMIN_ADD_EDIT_FORM: '/admin/form/:formId',
  ADMIN_CLONE_FORM: '/admin/form/clone/:formId',
  ADMIN_ADD_EDIT_USER: '/admin/user/:userId',
  ADMIN_MLS: '/admin/mls',
  ADMIN_MLS_SUBMISSION: '/admin/mls-submission',
  ADMIN_CASH_OFFERS: '/admin/cash-offers',
  ADMIN_BUYER_LEADS: '/admin/buyer-leads',
  ADMIN_MLS_CATEGORIES: '/admin/mls-categories',
  ADMIN_MLS_ABBREVIATION: '/admin/mls-abbreviation',
  ADMIN_COVERAGE: '/admin/mls-coverage',
  ADMIN_LANDING: '/admin/landing',
  ADD_EDIT_COMPANY: '/admin/company/:companyId',
  ADD_EDIT_MLS_CATEGORIES: '/admin/category/:mlsId',
  ADMIN_ADD_EDIT_MLS_ABBREVIATION: '/admin/abbreviation/:mlsId',
  ADMIN_ADD_EDIT_PRODUCT: '/admin/products/:productId',
  ADMIN_ADD_PRODUCT: '/admin/create-product',
  ADD_EDIT_PROPERTY: '/admin/property/:propertyId',
  ADMIN_ORDER_PRODUCTS: '/admin/order-products',
  ADMIN_OVERLAY_FORM: '/admin/forms/:sectionId',
  ADD_EDIT_COVERAGE: '/admin/coverage/:mlsLocationId',
  PREVIEW: '/admin/forms/preview/:sectionId',
  BASIC_FORM_PREVIEW: '/admin/form/preview/:formId',
  HOW_IT_WORK: '/how-it-work',
  TERM_CONDITION: '/terms-conditions',
  PROFILE: '/my-profile',
  ADMIN_PROFILE: '/admin/my-profile',
  FORGOT_PASSWORD: `${process.env.REACT_APP_BUY_SIDE_URL}/login/forgot-password`,
  RESET_PASSWORD: '/reset-password/',
  ABOUT: '/about/',
  AGENT: '/for-agent/',
  PRESS: '/press/',
  PRESS_MEDIA_COVERAGE: '/press/media-coverage',
  COMMERCIAL: '/commercial/',
  GLOSSARY: '/glossary/',
  CHECKLISTS: '/checklists/',
  PUBLIC_VIEW_LISTING: '/public-listing/:listingId',
  PROPERTY_LISTING_EDIT_FORM: '/property-listing-form/',
  EDIT_BILLING_ADDRESS: '/edit-billing-address',
  EDIT_SHIPPING_ADDRESS: '/edit-shipping-address',
  PLAN_PRICING: '/plan-pricing/',
  HOME_SELLING_GUIDE: '/home-selling-guide/',
  READY_TO_SELL: '/the-ready-to-sell-process/',
  GETTING_HOME_READY: '/getting-your-home-ready/',
  MARKETING_YOUR_HOME: '/guide-to-marketing-your-home/',
  CONTRACTS_CLOSING: '/guide-to-contracts-closing/',
  HOME_SELLING_BOOK: '/guide-to-home-selling-book/',
  ADD_EDIT_VOUCHER_TEMPLATE: '/admin/template/:templateId',
  VOUCHER_LISTINGS: '/voucher/listings',
  ADD_EDIT_VOUCHER: '/voucher/template/:voucherId',
  MLS_PREVIEW: '/admin/mls-preview/:state',
  MLS_CATEGORY: '/mls-category/:listingId',
  FAQ: '/faq',
  EBOOK: '/ebook',
  UNBOUNCE_LISTING: '/unbounce/listing',
  ZIP_NOT_SUPPORTED: '/unbounce/zip-not-supported',
  BUY: `${process.env.REACT_APP_BUY_SIDE_URL}/search`,
  SELL: `${process.env.REACT_APP_BUY_SIDE_URL}/sell`,
  AGENTS: `${process.env.REACT_APP_BUY_SIDE_URL}/agents`,
  PIVACY_POLICY: `${process.env.REACT_APP_BUY_SIDE_URL}/privacy-policy`,
  TERMS_AND_CONDITIONS: `${process.env.REACT_APP_BUY_SIDE_URL}/terms-of-service`,
  LICENSES_AND_DISCLOSURES: `${process.env.REACT_APP_BUY_SIDE_URL}/licenses-and-disclosures`,
  COMPANY: `${process.env.REACT_APP_BUY_SIDE_URL}/company`,
  MY_FAVORITES: `${process.env.REACT_APP_BUY_SIDE_URL}/properties/favorites`,
  SAVED_SEARCHES: `${process.env.REACT_APP_BUY_SIDE_URL}/properties/saved-searches`,
  SETTINGS: `${process.env.REACT_APP_BUY_SIDE_URL}/settings`,
  MORTAGE: `${process.env.REACT_APP_BUY_SIDE_URL}/mortgage`,
  SHOWING_REQUEST: '/request-showing',
  SHOWING_REQUEST_FORM: '/request-showing/:mlsid',
  OFFER_SUBMIT: '/offer-submit',
  OFFER_SUBMIT_FORM: '/offer-submit/:mlsid',
  BLOG: 'https://www.unrealestate.com/blog',
  GET_LISTED_FREE: '/list-your-home-v2',
  FIND_A_HOME: 'https://unrealestate.com/search',
  MORTGAGE_URL: process.env.REACT_APP_MORTGAGE_URL,
  SIGN_IN: '/login',
  SIGN_UP: '/signup',
  CASH_OFFER_ARTICLE: `${process.env.REACT_APP_BUY_SIDE_URL}/blog/how-sellers-can-sell-to-a-cash-buyer-fast`,
};