import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { Modal } from 'antd';
import { Spinner } from 'react-bootstrap';
import accept from 'attr-accept';
import update from 'immutability-helper';
import { useDrop, useDrag } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import {
  changingImagesOrderSelector,
  deletingImagesSelector,
  imagesUploadingProgressSelector,
  listingImagesSelector,
  totalSavingSelector,
  propertyPriceSelector,
  mlsCategoryIdSelector,
  propertyTypeIdSelector,
  propertyOwnersSelector,
  draftListingIdSelector,
  selectedAddressSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { propertyTypesSelector, mlsCategoriesSelector } from '../../../Reducers/LookupReducer';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { onboardingActionCreatorsV2, onboardingActions } from '../../../Actions/OnboardingActionsV2';
import { HeadHouseIcon } from '../../Shared/Components/Icons/HeadHouseIcon';
import { RemovePhotoIcon } from '../../Shared/Components/Icons/RemovePhotoIcon';
import { gTag, getPropertyOwnerFields } from '../../../Utilities/utils';
import { readAndCompressImage } from 'browser-image-resizer';
import { guid } from '../../../Utilities/Strings';
import { PropertyPhoto } from '../Components/PropertyPhoto';
import { LYFSteps, ownerTypes } from '../../../Constants/CommonConstants';
import { userDetailsSelector } from '../../../Reducers/UserReducer';
import CashOfferPanel from '../Components/CashOfferPanel';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';

export const ItemTypes = {
  CARD: 'card',
};

const PRO_PHOTOS_URL = 'http://showingtimeplus.com/order/VRX';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
    margin-left: 13rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;
  max-width: 28rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    max-width: 40rem;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
    max-width: 40rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 1.5rem;
  max-width: 75%;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const SkipButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileSkipButton = styled(SubmitButton)`
  background: none;
  min-width: 8rem;
  display: none;
  align-self: center;
  border-width: 1px;
  border-style: dashed;
  font-weight: 800;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const TotoalSavingValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TotalSavingText = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 6px;
  padding-bottom: 0;
  align-self: center;
`;

const TotoalSavingValueText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  margin-left: 0.5rem;
`;

const AveragePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background: ${({ theme }) => theme.colors.grayBackground};
  border-radius: 10px;
  padding: 0.75rem 1.5rem;
  justify-content: center;
`;

const TotalSavingContainer = styled(AveragePriceContainer)`
  background: ${({ theme }) => theme.lightGreen};
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PhotoGalleryPlaceHolder = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme, error, active }) => {
    if (error) return theme.colors.red[500];
    if (active) return '#5ef292';
    return theme.colors.green[900];
  }};
  width: 100%;
  height: 18rem;
  margin-right: 0.75rem;
  margin-bottom: 0;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme, active }) => (active ? '#d4ffe3' : theme.light)};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 1.5rem;
    padding-bottom: 3.5rem;
  }
`;

const PhotoGalleryListPlaceHolder = styled(PhotoGalleryPlaceHolder)`
  max-height: 15rem;
  height: unset;
  min-height: 11rem;
  margin-top: 1rem;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme, error, active }) => {
    if (error) return theme.colors.red[500];
    if (active) return '#5ef292';
    return theme.colors.green[900];
  }};
  width: 100%;
  margin-bottom: 0;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme, active }) => (active ? '#d4ffe3' : theme.light)};

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-height: 11rem;
    ${({ isOdd }) => (isOdd ? 'width: 47%' : '')};
    ${({ isOdd }) =>
      isOdd
        ? `
      :nth-child(odd) {
        margin-right: 1.5rem;
      }
    `
        : ''};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 12rem;
    margin-right: 0;
    ${({ isOdd }) => (isOdd ? 'width: 47%' : '')};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-height: 15rem;
    ${({ isOdd }) => (isOdd ? 'width: 48%' : '')};
  }
`;

const PhotoGalleryCoverPhotocontainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  min-height: 15rem;
  color: ${({ theme, error }) => (error ? theme.colors.red[500] : theme.colors.green[900])};
  background-color: ${({ theme }) => theme.gray};
  margin-right: 0.75rem;
  margin-top: 0.5rem;
`;

const PhotoGalleryCoverPhoto = styled.img`
  width: 100%;
  height: 18rem;
  object-fit: cover;
  border-radius: 8px;
`;

const PhotoGalleryPlaceHolderTitle = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 2rem;
`;

const PhotoGalleryPlaceHolderSubTitle = styled.span`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #5b605c;
`;

const PhotoGalleryPlaceHolderContent = styled.div`
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PhotoGalleryBottomText = styled.div`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const PhotoGalleryBottomTextContainer = styled.div`
  display: flex;
  padding-top: 0.75rem;
`;

const PhotoGalleryBottomTextLink = styled.label`
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  padding-left: 0.5rem;
  &:hover {
    cursor: pointer;
    color: #b01f24;
  }
`;

const Loader = styled(Spinner)`
  color: ${({ theme }) => theme.dark};
  align-self: center;
  margin: 4px;
  position: absolute;
`;

const RemovePhotoIconContainer = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  align-self: flex-end;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

const CoverPhotoBage = styled.span`
  padding: 4px;
  position: absolute;
  top: 15px;
  left: 15px;
  align-self: flex-start;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: #1e2420;
  background-color: ${({ theme }) => theme.lightGreen};
  border-radius: 4px;
`;

const RestPhotoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const LinkStyledButton = styled.button`
  font-family: 'National-Regular';
  text-decoration-line: underline;
  background: none;
  color: ${({ theme }) => theme.dark};
  &:hover {
    color: ${({ theme }) => theme.dark};
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const PropPhotosLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  height: 3rem;
  font-size: 0.75rem;
  background-color: ${({ theme }) => theme.lightGreen};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  &:hover {
    color: ${({ theme }) => theme.dark};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const PropertyPhotos = () => {
  const [showListingPreview, setShowListingPreview] = useState(false);
  const [showPhotoSuggestionsModal, setShowPhotoSuggestionsModal] = useState(false);
  const images = useSelector(listingImagesSelector);
  const coverPhoto = images?.[0];
  const dispatch = useDispatch();
  const totalSaving = useSelector(totalSavingSelector);
  const imagesUploadingProgress = useSelector(imagesUploadingProgressSelector);
  const deletingImages = useSelector(deletingImagesSelector);
  const changingImagesOrder = useSelector(changingImagesOrderSelector);
  const propertyTypeId = useSelector(propertyTypeIdSelector);
  const mlsCategoryId = useSelector(mlsCategoryIdSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const mlsCategories = useSelector(mlsCategoriesSelector);
  const propertyOwners = useSelector(propertyOwnersSelector);
  const coverPhotoId = coverPhoto?.id;
  const userDetails = useSelector(userDetailsSelector);
  const draftListingId = useSelector(draftListingIdSelector)
  const selectedAddress = useSelector(selectedAddressSelector);

  const findCard = useCallback(
    (id) => {
      const card = images.filter((c) => `${c.id}` === id)[0];
      return {
        card,
        index: images.indexOf(card),
      };
    },
    [images]
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      if (!id || id === 'undefined') {
        return;
      }

      const { card, index } = findCard(id);
      const imagesArr = update(images, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      });

      if (imagesArr.some((x) => !x)) {
        return;
      }

      dispatch({
        type: onboardingActions.GET_LISTING_PHOTO_BY_ID_RESOLVED_V2,
        payload: imagesArr,
      });
    },
    [dispatch, images, findCard]
  );

  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { id: coverPhotoId, originalIndex: 0 },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [coverPhotoId, moveCard]
  );
  const [, dropCover] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      hover({ id: draggedId, originalIndex }) {
        if (originalIndex !== 0) {
          const { index: overIndex } = findCard(coverPhotoId);
          moveCard(draggedId, overIndex);
        }
      },
      drop({ id: draggedId, originalIndex }) {
        if (originalIndex !== 0) {
          const { index: overIndex } = findCard(coverPhotoId);
          changeOrder(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );
  const changeOrder = useCallback(
    (id, atIndex) => {
      if (!id || id === 'undefined') {
        return;
      }

      const { card, index } = findCard(id);
      const imagesArr = update(images, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      }).map((i) => i.id);

      if (imagesArr.some((x) => !x)) {
        return;
      }

      dispatch(onboardingActionCreatorsV2.reorderListingPhotos(imagesArr));
    },
    [dispatch, images, findCard]
  );

  const onDrop = async (e, photos) => {
    let files = [];
    files = [...e.files];
    files = files.filter((file) => file.type === 'application/x-moz-file' || accept(file, 'image/*'));
    onPhotosAdded({ target: { files } });
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        if (onDrop) {
          onDrop(item);
        }
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const handleContinue = () => {
    const category = mlsCategories.find((o) => Number(o.id) === Number(mlsCategoryId))?.name;
    const name = propertyTypes.find((o) => Number(o.id) === Number(propertyTypeId))?.name;

    gTag({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 5,
          },
          products: [
            {
              name,
              category,
              price: propertyPrice,
              upload_photo: images.length ? 'yes' : 'no',
            },
          ],
        },
      },
    });
    
    if (
      propertyOwners.length === 1 &&
      !propertyOwners[0]?.email?.value &&
      !propertyOwners[0]?.name?.value &&
      !propertyOwners[0]?.phone?.value &&
      propertyOwners[0].ownerType?.value === ownerTypes.individual
    ) {
      const ownerFileds = {
        email: userDetails?.email,
        name: `${userDetails?.firstName ?? ''}${userDetails?.firstName ? ' ' : ''}${userDetails?.lastName ?? ''}`,
        phone: userDetails?.phoneNumber,
      };
      dispatch(
        onboardingActionCreatorsV2.setPropertyOwners([getPropertyOwnerFields(ownerTypes.individual, ownerFileds)])
      );
    }

    dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_TITLE));
  };

  const onPhotosAdded = async (e) => {
    if (!e.target.files.length) {
      return;
    }
    for (let value of Array.from(e.target.files)) {
      processPhoto(value);
    }
    if (e?.target?.value) {
      e.target.value = '';
    }
  };

  const createThumbnail = async (photo) => {
    const config = {
      quality: 0.8,
      maxWidth: 300,
      maxHeight: 300,
      autoRotate: true,
      debug: false,
    };

    return await readAndCompressImage(photo, config);
  };

  const processPhoto = async (file) => {
    const fileExtension = file.name.split('.').pop();
    const folderId = guid();
    const thumb = await createThumbnail(file);
    const thumbName = `${file.name.replace(`.${fileExtension}`, `_thumb.${fileExtension}`)}`;
    const thumbFile = new File([thumb], thumbName);
    const filePath = `${folderId}/${file.name.replaceAll('#', '-')}`;
    const thumbFilePath = `${folderId}/${thumbFile.name.replaceAll('#', '-')}`;
    dispatch(
      onboardingActionCreatorsV2.addListingPhotoV2({
        file,
        filePath,
        url: window.URL.createObjectURL(file),
        thumbFile,
        thumbFilePath,
      })
    );
  };

  const removePhoto = useCallback(
    (id, order) => {
      dispatch(onboardingActionCreatorsV2.deleteListingPhotoIdV2(id, order));
    },
    [dispatch]
  );

  return (
    <Container>
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>5/9</StepsText>
            </TitleContainer>
            <TitleText>Add your photos</TitleText>
            <Text>
              Be sure to read our tips on
              <LinkStyledButton onClick={() => setShowPhotoSuggestionsModal(true)}>
                How to take photos of your property.
              </LinkStyledButton>
              We will process and upload the photos in the order in which they appear. If you need to rearrange them you
              may do so by dragging and dropping them into place.
            </Text>
            <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
            <CashOfferPanel  listindAddressDetails={{
              ...(selectedAddress || {})
            }} draftListingId={draftListingId} price={propertyPrice}/>
            <input
              type="file"
              accept="image/jpeg,image/png"
              multiple={true}
              id="photoUpload"
              name="image"
              onChange={(e) => {
                e.persist();
                onPhotosAdded(e);
              }}
              style={{ display: 'none' }}
            />
            {images.length < 1 ? (
              <PhotoGalleryPlaceHolder htmlFor="photoUpload" ref={drop} active={canDrop && isOver}>
                <PhotoGalleryPlaceHolderContent>
                  <HeadHouseIcon />
                  <PhotoGalleryPlaceHolderTitle>Add your photos</PhotoGalleryPlaceHolderTitle>
                  <PhotoGalleryPlaceHolderSubTitle>
                    Drag photos here or click here to select photos
                  </PhotoGalleryPlaceHolderSubTitle>
                </PhotoGalleryPlaceHolderContent>
              </PhotoGalleryPlaceHolder>
            ) : (
              coverPhoto?.url && (
                <PhotoGalleryCoverPhotocontainer ref={(node) => drag(dropCover(node))}>
                  {!imagesUploadingProgress[0] && coverPhoto?.id && (
                    <RemovePhotoIconContainer
                      onClick={() => removePhoto(coverPhoto?.id)}
                      disabled={deletingImages || changingImagesOrder}
                    >
                      <RemovePhotoIcon />
                    </RemovePhotoIconContainer>
                  )}
                  {!imagesUploadingProgress[0] && <CoverPhotoBage>Cover Photo</CoverPhotoBage>}
                  <PhotoGalleryCoverPhoto
                    style={{ opacity: imagesUploadingProgress[0] ? 0.5 : 1 }}
                    src={coverPhoto?.url || null}
                  />
                  {imagesUploadingProgress[0] && <Loader animation="border" />}
                </PhotoGalleryCoverPhotocontainer>
              )
            )}
            <RestPhotoContainer>
              <>
                {images.map((image, idx) => (
                  <PropertyPhoto
                    photo={image}
                    key={image?.id || idx}
                    id={`${image.id}`}
                    index={idx}
                    moveCard={moveCard}
                    findCard={findCard}
                    removePhoto={removePhoto}
                    imagesUploadingProgress={imagesUploadingProgress}
                    deletingImages={deletingImages}
                    changingImagesOrder={changingImagesOrder}
                    changeOrder={changeOrder}
                  />
                ))}
                {images.length > 0 && (
                  <PhotoGalleryListPlaceHolder
                    htmlFor="photoUpload"
                    active={canDrop && isOver}
                    isOdd={(images.length - 1) % 2}
                    ref={drop}
                  >
                    <PhotoGalleryPlaceHolderContent>
                      <HeadHouseIcon />
                      <PhotoGalleryPlaceHolderTitle>Add your photos</PhotoGalleryPlaceHolderTitle>
                      <PhotoGalleryPlaceHolderSubTitle>
                        Drag photos here or click here to select photos
                      </PhotoGalleryPlaceHolderSubTitle>
                    </PhotoGalleryPlaceHolderContent>
                  </PhotoGalleryListPlaceHolder>
                )}
              </>
            </RestPhotoContainer>
            <PhotoGalleryBottomTextContainer>
              <PhotoGalleryBottomText>Upload from your device.</PhotoGalleryBottomText>
              <PhotoGalleryBottomTextLink htmlFor="photoUpload">Upload</PhotoGalleryBottomTextLink>
            </PhotoGalleryBottomTextContainer>

            {!images.length && (
              <MobileSkipButton type="button" onClick={handleContinue}>
                Skip for now
              </MobileSkipButton>
            )}
            {totalSaving > 0 && (
              <TotalSavingContainer>
                <TotalSavingText>Your potential savings by listing with Unreal Estate</TotalSavingText>
                <TotoalSavingValueContainer>
                  <TotoalSavingValueText>
                    <CurrencyFormat
                      prefix="$"
                      value={totalSaving}
                      thousandSeparator=","
                      decimalSeparator="."
                      displayType="text"
                      suffix=".00"
                    />
                  </TotoalSavingValueText>
                </TotoalSavingValueContainer>
              </TotalSavingContainer>
            )}
            <ButtonsContainer>
              <MobilePreviewButton
                onClick={() => {
                  setShowListingPreview(true);
                }}
              >
                <EyeButton />
              </MobilePreviewButton>
              <PropPhotosLink href={PRO_PHOTOS_URL} target="_blank" rel="noopener noreferrer">
                Order Pro-Photos
              </PropPhotosLink>
              <BackButton type="button" onClick={() => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_DETAILS))}>
                Back
              </BackButton>
              {!images.length && (
                <SkipButton type="button" onClick={handleContinue}>
                  Skip for now
                </SkipButton>
              )}
              <SubmitButton type="button" onClick={handleContinue}>
                Continue
              </SubmitButton>
            </ButtonsContainer>
          </FormContainer>
          <ListingPreviewDesktopContainer />
        </>
      )}
      <Modal
        title="How to take photos of your property"
        visible={showPhotoSuggestionsModal}
        onCancel={() => setShowPhotoSuggestionsModal(false)}
        onOk={() => setShowPhotoSuggestionsModal(false)}
        destroyOnClose={true}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>The MLS requires the first cover photo of your property to be of the front exterior.</p>
        <p>
          <strong>Photo suggestions:</strong>
          <br />
          Photos are the most important step to turning your property into a digital asset for buyers to shop online.
          Your goal is to get professional photos, or consider advice like below to get as close to professional as
          possible:
        </p>
        <p>Take photos at the golden hour an hour after sunrise or an hour before sunset.</p>
        <p>
          Exterior-take straight on and shots at each corner 360 degrees around the property and if possible, near the
          building in entertaining and entrance spaces take photos going away from the property if it has a value add to
          showcase the location or view the property has to offer.
        </p>
        <p>Interior-to provide in the order of a walking tour of the home:</p>
        <ul class="ak-ul">
          <li>
            <p>
              Turn on all lights in the property and clear every room to be new construction model empty. (if necessary,
              move items out to a room not in view of the photo and move them back as you move through the house. Keep
              all doors open and increase the expansive view of the property along with leaving shades and blinds half
              or fully open (use your judgement).
            </p>
          </li>
          <li>
            <p>
              Take photos holding the camera lower at belly height, use .5 wide angle lens when possible, take 2 shots
              per room (1 magazine shot showcasing the best view if printed in a magazine and 1 square footage shot
              standing in the corner where the largest amount of floor space can be seen, ensure size of room is
              perceived by ignoring the ceiling and getting the room with lots of floor in the photo.
            </p>
          </li>
          <li>
            <p>
              Once finished with these directions, you will have 30+ photos and will be in a good position to pick your
              favorites to post on your listing
            </p>
          </li>
        </ul>
      </Modal>
    </Container>
  );
};

export default PropertyPhotos;
