import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { onboardingActionCreatorsV2 } from '../../../Actions/OnboardingActionsV2';
import { FormView } from '../Components/FormView';
import { FormImageView } from '../Components/FormImageView';
import {
  draftListingIdSelector,
  propertyDetailsFormSelector,
  draftFormsFieldsSelector,
  propertyPriceSelector,
  mlsCategoryIdSelector,
  propertyTypeIdSelector,
  selectedAddressSelector,
} from '../../../Reducers/OnboardingReducerV2';
import { propertyTypesSelector, mlsCategoriesSelector } from '../../../Reducers/LookupReducer';
import { gTag, scrollToFirstErroField } from '../../../Utilities/utils';
import LogRocket from 'logrocket';
import CashOfferPanel from '../Components/CashOfferPanel';
import ListingPreviewMobileContainer from '../Components/ListingPreviewMobileContainer';
import { EyeButton } from '../../Shared/Components/Icons/EyeButton';
import ListingPreviewDesktopContainer from '../Components/ListingPreviewDesktopContainer';
import { EmbeddedYoutubeHintsPlayer } from '../Components/EmbeddedYoutubeHintsPlayer';
import { LYFSteps } from '../../../Constants/CommonConstants';

const Container = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: start;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.darkGreen[100]};
  background: 'transparent';
  margin-bottom: 1rem;
  margin-top: 3rem;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 20rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-right: 2rem;
    margin-left: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 62rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  width: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 400px;
    margin-right: 2rem;
    padding: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    min-width: 620px;
    padding: 0;
    margin-right: 8rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.div`
  font-size: 32px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1rem;
`;

const SubTilteText = styled(TitleText)`
  font-size: 18px;
  line-height: 28px;
`;

const Text = styled(TitleText)`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0.5rem;
  max-width: 65%;
`;

const StepsText = styled(SubTilteText)`
  font-size: 18px;
  font-family: 'National-Regular';
  font-style: normal;
  font-weight: 400;
  color: #919592;
  align-self: flex-end;
`;

const SubmitButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-width: 10rem;
  line-height: 1.2;
  border-radius: 64px;
  font-weight: 700;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 3rem;
  font-size: 0.75rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background-color: ${({ theme }) => theme.colors.violet[200]};
  color: ${({ theme }) => theme.colors.green[900]};
  margin-top: 2rem;
  align-self: flex-end;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.mouseballgray[100]};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 7rem;
  }
`;

const BackButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    min-width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    min-width: 5rem;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: flex-end;
  }
`;

const SkipButton = styled(SubmitButton)`
  background: none;
  margin-right: 1rem;
  min-width: 8rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const MobileSkipButton = styled(SubmitButton)`
  background: none;
  min-width: 8rem;
  display: none;
  align-self: center;
  border-width: 1px;
  border-style: dashed;
  font-weight: 800;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

const MobilePreviewButton = styled.button`
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: baseline;
  width: min-content;
  height: auto;
  min-width: 2.5rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  padding: 0px;
  background-color: #ffffffff;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const PropertyDetails = () => {
  const dispatch = useDispatch();
  const draftListingId = useSelector(draftListingIdSelector);
  const propertyDetailsForm = useSelector(propertyDetailsFormSelector);
  const draftFormsFields = useSelector(draftFormsFieldsSelector);
  const propertyTypeId = useSelector(propertyTypeIdSelector);
  const mlsCategoryId = useSelector(mlsCategoryIdSelector);
  const propertyPrice = useSelector(propertyPriceSelector);
  const propertyTypes = useSelector(propertyTypesSelector);
  const mlsCategories = useSelector(mlsCategoriesSelector);
  const selectedAddress = useSelector(selectedAddressSelector);
  const [errors, setErrors] = useState({});
  const [showListingPreview, setShowListingPreview] = useState(false);

  useEffect(() => {
    dispatch(onboardingActionCreatorsV2.getPropertyDetailsFormV2(draftListingId));
  }, []);

  const validateForm = () => {
    const tempErrors = {};
    if (!propertyDetailsForm || !propertyDetailsForm?.groups?.length) {
      return;
    }
    propertyDetailsForm.groups.forEach((group) => {
      group.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (field.required && !draftFormsFields.find((x) => x.formFieldId === field.formFieldId)?.fieldValue) {
            tempErrors[field.formFieldId] = true;
            const fieldNode = document.querySelector(`[name="${field.formFieldId}"]`);
            if (!fieldNode) {
              LogRocket.captureException(new Error('broken_property_details_form_detected'), {
                tags: {
                  errorType: 'broken_property_details_form_detected',
                },
                extra: {
                  type: 'PROPERTY DETAILS FORM VALIDATION FAILED',
                  formId: propertyDetailsForm.formId,
                  formFieldId: field.formFieldId,
                  formFieldName: field.displayName,
                  formGroupSectionId: section.formGroupSectionId,
                },
              });
            }
          }
        });
      });
    });

    setErrors({ ...tempErrors });
    const errorsArr = Object.keys(tempErrors);

    if (errorsArr.length > 0) {
      scrollToFirstErroField(errorsArr);
    }

    return errorsArr.length > 0;
  };

  const onFieldBlur = (field) => {
    dispatch(onboardingActionCreatorsV2.updateDraftListingV2());

    delete errors[field.formFieldId];
    setErrors({ ...errors });
  };
  const skipToNextStep = () => dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_PHOTOS));

  const handleContinue = () => {
    if (validateForm()) {
      return;
    }
    const category = mlsCategories.find((o) => Number(o.id) === Number(mlsCategoryId))?.name;
    const name = propertyTypes.find((o) => Number(o.id) === Number(propertyTypeId))?.name;

    gTag({
      event: 'checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 4,
          },
          products: [
            {
              name,
              category,
              price: propertyPrice,
            },
          ],
        },
      },
    });
    dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_PHOTOS));
  };

  const handleBack = () => {
    dispatch(onboardingActionCreatorsV2.setListingStepV2(LYFSteps.PROPERTY_BASICS));
  };

  return (
    <Container id="container">
      {showListingPreview ? (
        <ListingPreviewMobileContainer onClose={() => setShowListingPreview(false)} />
      ) : (
        <>
          <FormContainer>
            <TitleContainer>
              <SubTilteText>List your property</SubTilteText>
              <StepsText>4/9</StepsText>
            </TitleContainer>
            <TitleText>Property Details</TitleText>
            <Text>
              Please check your personal details. The phone number and email you registered with will be used as the
              contact for the property.
            </Text>
            <EmbeddedYoutubeHintsPlayer mobile={true} width="296" height="536" />
            <CashOfferPanel listindAddressDetails={{
              ...(selectedAddress || {})
            }} draftListingId={draftListingId} price={propertyPrice} />
            {propertyDetailsForm &&
              (propertyDetailsForm.groups.some((x) => x.formGroupType === 3) ? (
                <FormImageView form={propertyDetailsForm} onFieldBlur={onFieldBlur} errors={errors}></FormImageView>
              ) : (
                <FormView form={propertyDetailsForm} onFieldBlur={onFieldBlur} errors={errors}></FormView>
              ))}
            <MobileSkipButton type="button" onClick={skipToNextStep}>
              Skip for now
            </MobileSkipButton>
            <ButtonsContainer>
              <MobilePreviewButton
                onClick={() => {
                  setShowListingPreview(true);
                }}
              >
                <EyeButton />
              </MobilePreviewButton>
              <BackButton type="button" onClick={handleBack}>
                Back
              </BackButton>
              <SkipButton type="button" onClick={skipToNextStep}>
                Skip for now
              </SkipButton>
              <SubmitButton type="button" onClick={handleContinue}>
                Continue
              </SubmitButton>
            </ButtonsContainer>
          </FormContainer>
          <ListingPreviewDesktopContainer />
        </>
      )}
    </Container>
  );
};

export default PropertyDetails;
